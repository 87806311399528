import { Menu } from '@headlessui/react'
import { useRouter } from 'next/router'

import { CheckIcon } from '@heroicons/react/outline'
import { ReactNode } from 'react'
import { analytics } from '~/lib/segment'
import { useAppContext } from '~/lib/state_management/context/appContext'

type Props = {
  label: string
  url?: string
  callback?: () => void
  allowCheckmarks: boolean
  icon?: ReactNode
  isChecked?: boolean
  mobileOnly?: boolean
  signedInOnly?: boolean
}

export default function DropdownMenuItem({
  label,
  url,
  callback,
  allowCheckmarks,
  isChecked,
  mobileOnly,
  signedInOnly,
}: Props) {
  const router = useRouter()
  const appContext = useAppContext()

  const inactiveClasses = ''
  const constClasses = 'pl-2 flex flex-row items-center gap-0 '

  const showMenuItem = () => {
    if (signedInOnly) {
      return appContext.isLoggedIn
    }
    return true
  }

  const onButtonClick = () => {
    analytics.track('DropdownMenuItem Clicked', {
      title: label,
      url,
    })

    if (callback) {
      callback()
    } else if (url) {
      router.push(url)
    }
  }

  return (
    <>
      {showMenuItem() && (
        <Menu.Item as="div" className={`${mobileOnly ? 'md:hidden' : ''}`}>
          {({ active }) => (
            <div className={`${active ? 'bg-gray-700' : inactiveClasses} ${constClasses}`}>
              {allowCheckmarks && (
                <CheckIcon
                  className={`${isChecked ? '' : 'invisible'} h-[20px] w-[20px] ${
                    active ? 'text-white' : 'text-orange'
                  }`}
                />
              )}
              <button
                className="group flex w-full items-center whitespace-nowrap py-4 pl-2 pr-4 text-xs text-white"
                onClick={onButtonClick}
              >
                {label}
              </button>
            </div>
          )}
        </Menu.Item>
      )}
    </>
  )
}
